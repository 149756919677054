import { delegateEvent, domReady, initOnIntersection } from 'utilities/dom';
import { observeAssetCounts } from 'features/asset';
import { renderCartItems } from 'features/cart/desktop';
import { getMyStore } from 'features/bopus/api';
import { Reporter, visibilityObserver } from 'features/dataCapture';
import getMyStoreTemplateWithMap from 'features/myStore/desktop';
import { utagLinkHomepage, utagLinkManageAccount, utagViewArtificialPageView } from 'features/tealium';
import { prefetchWebpackAssetSet } from 'features/webpack';
import { setGenericEvent } from 'features/tealium/ga4/custom';

import dynamicImport from '../../../features/webpack/dynamicImport.macro';

const trackArtificalPageViewEvent = utagViewArtificialPageView();

function initMyStore () {

	if (window.lp.globals.isUserBopusEligible) {

		const container = document.querySelector('.hpMyStore');

		if (container) {

			getMyStore()
				.then((response) => {

					if (response.IsSuccess) {

						const store = response.ApiResult;

						container.innerHTML = getMyStoreTemplateWithMap(store, window.lp.globals.secureSiteURL);
						container.classList.remove('hidden');

					}

				})
				.catch((error) => {

					console.log(error);

				});

			container.addEventListener(
				'click',
				delegateEvent((e) => {

					e.preventDefault();
					const storeID = e.target.getAttribute('data-storeid');
					$('body').lpModal({
						onInit: true,
						width: 600,
						height: 280,
						src: `/stores/ScheduleAppointment/${storeID}`,
						lpModalClass: 'lpModal--alt1'
					});

					return false;

				}, '#hpStoreAppointment')
			);

		}

	}

}

domReady(() => {

	const trackHomePageEvents = utagLinkHomepage();

	const $hpSlider = $('.hpSlider');
	// filter out elements with ids starting with "dy"
	// these are injected into the dom by Dynamic Yield inside hpSlider div which trigger slider
	if ($hpSlider.children(':not([id^="dy"])').length > 1) {

		$hpSlider.nivoSlider({
			effect: 'fade',
			controlNav: false,
			directionNav: false,
			pauseTime: 3500
		});

	}

	const observeAndTrackElements = (skuViews) => {

		const { reporter } = window.lp.dataCapture;
		const observer = visibilityObserver((element) => {

			const data = Reporter.getSkuViewData(element);
			reporter.track(data);

		});
		skuViews.forEach((element) => observer.observe(element));

	};

	if (window.lp.globals.canShowCertona) {

		if (window.lp.globals.enableDataCapture) {

			// Recently Viewed tracking
			document.addEventListener('recommendedItems:GF_homepage:loaded', () => {

				observeAndTrackElements(document.querySelectorAll('#recentlyViewedWidgetContainer img[data-sku]'));

				const recentlyViewItems = document.querySelectorAll(
					'#recentlyViewedWidgetContainer .certonaWidgetContainer__item a'
				);
				const viewAllRecentlyViewedBtn = document.querySelector('#viewAllRecentlyViewedBtn');

				if (recentlyViewItems.length) {

					setTimeout(() => {

						trackHomePageEvents({
							eventAction: 'LoadCertona-RecentlyViewed',
							nonInteraction: 1
						});

					}, 100);

				}

				recentlyViewItems.forEach((eachItem, i) => {

					eachItem.addEventListener(
						'click',
						(e) => {

							trackHomePageEvents({
								eventAction: 'ClickCertona-RecentlyViewed',
								eventLabel: e.target.getAttribute('data-sku')
							});

							setGenericEvent({ event_name: 'rcntvw_product_click', item_position_number: i + 1 });

						},
						true
					);

				});

				if (viewAllRecentlyViewedBtn) {

					viewAllRecentlyViewedBtn.addEventListener(
						'click',
						() => {

							trackHomePageEvents({ eventAction: 'ClickCertona-RecentlyViewed', eventLabel: 'View All' });

							setGenericEvent({ event_name: 'rcntvw_viewall_click' });

						},
						true
					);

				}

			});

			// Just For You tracking
			document.addEventListener('recommendedItems:lastCategory:loaded', () => {

				observeAndTrackElements(document.querySelectorAll('#justForYouWidgetContainer img[data-sku]'));

				const recentlyViewItems = document.querySelectorAll(
					'#justForYouWidgetContainer .certonaWidgetContainer__item a'
				);
				const viewAllJustForYouBtn = document.querySelector('#viewAllJustForYouBtn');

				if (recentlyViewItems.length) {

					setTimeout(() => {

						trackHomePageEvents({
							eventAction: 'LoadCertona-JustForYou',
							nonInteraction: 1
						});

					}, 100);

				}

				recentlyViewItems.forEach((eachItem) => {

					eachItem.addEventListener(
						'click',
						(e) => {

							trackHomePageEvents({
								eventAction: 'ClickCertona-JustForYou',
								eventLabel: e.target.getAttribute('data-sku')
							});

						},
						true
					);

				});

				if (viewAllJustForYouBtn) {

					viewAllJustForYouBtn.addEventListener(
						'click',
						() => {

							trackHomePageEvents({ eventAction: 'ClickCertona-JustForYou', eventLabel: 'View All' });

						},
						true
					);

				}

			});

			// Top Selling Designs tracking
			document.addEventListener('recommendedItems:home:loaded', () => {

				observeAndTrackElements(document.querySelectorAll('.hpMoreYouMayLike img[data-sku]'));

			});

		}

		const { globals } = window.lp;

		if (
			globals
			&& (globals.isProfessional
				|| globals.isCustomer
				|| (globals.isLoggedIn && !globals.isCustomer)
				|| (!globals.isLoggedIn && !globals.isStoreInSession))
		) {

			const pixleeElement = document.getElementById('pixleeWidget');
			const marker = document.getElementById('pixleeWidgetHomeScrollMarker');
			const pixleeWidgetShowNextPrevButtonHomePageToggleOn = JSON.parse(
				window.lp.globals.pixleeWidgetShowNextPrevButtonHomePageToggleOn
			);

			if (pixleeElement) {

				if (window.isIpad()) {

					// container width for iPad needs to be handled separately
					const pixleeContainer = document.querySelector('.instagramFeed--4Across.pixleeContainer');
					pixleeContainer?.classList.add('iPadWidth');

				}

				initOnIntersection(
					marker,
					() => {

						dynamicImport('desktop/home-pixleeInstagramFeed', './pixleeInstagramFeed').then((module) => {

							const initHomeInstagramFeed = module.default;
							initHomeInstagramFeed(pixleeElement, pixleeWidgetShowNextPrevButtonHomePageToggleOn);

						});

					},
					'0px 0px 500px 0px'
				);

			}

		}

		window.lp.certona.getRecommendedItems();

	}

	if (window.lp.globals.isHospitality) {

		$('#lpContainer').addClass('isHospitality');

	}

	// temporary change while stores are closed we do not want to initialize My Store section on homepage
	// initMyStore();

	prefetchWebpackAssetSet('desktop/sort');

});

window.addEventListener('load', () => {

	const trackHomePageEvents = utagLinkHomepage();
	const trackManageAccountEvent = utagLinkManageAccount();

	const currentUrl = window.location.href;
	const preUrl = document.referrer;
	if (preUrl.indexOf('?') > 0) {

		const url = new URL(preUrl);
		const urlParams = new URLSearchParams(url.search);

		const isForgotPassword = urlParams.get('isForgotPassword');
		if (isForgotPassword && JSON.parse(isForgotPassword.toLowerCase())) {

			trackManageAccountEvent({
				eventAction: 'Click-Password-Reset-Sign-In',
				eventLabel: currentUrl
			});

		}

	}

	// Track GA Event for - User click on any SKU in the New & Trending Products Certona widget
	const newAndTrendingItems = document.querySelectorAll('.sortMYMLProdContainer');

	newAndTrendingItems.forEach((eachItem) => {

		eachItem.addEventListener(
			'click',
			(e) => {

				trackHomePageEvents({
					eventAction: 'ClickNew&TrendingProducts',
					eventLabel: e.target.getAttribute('data-sku')
				});

			},
			true
		);

	});

});
